<template>
  <v-app>
    <div
      style="
        background-image: url(/media/anjungan/BG-1280x1024.jpg);
        height: 100%;
        background-size: cover;
      "
    >
      <!-- Header::Start -->
      <div class="curved-header sample1">
        <div class="row px-10">
          <div class="col-1 pt-10">
            <img
              style="
                max-height: 198px;
                min-height: 198px;
                position: relative;
                top: 60px;
                left: 11px;
              "
              :src="config.city_logo"
              alt="LOGO DESA"
            />
          </div>
          <div class="col text-center pt-10 px-19">
            <h1 style="font-size: 54px; color: #1dac74">
              ANJUNGAN DESA MANDIRI
            </h1>
            <span
              style="font-size: 25px; text-align: justify; color: #3b4c42"
              >{{ config.address }}</span
            >
          </div>
        </div>
      </div>
      <!-- Header::End -->

      <!-- Menu::Start -->
      <div class="container mt-4" style="overflow-y: scroll; max-height: 509px">
        <div class="d-flex justify-content-end mt-5">
          <div>
            <!-- <b-button
              type="button"
              class="ml-2 btn-cancel btn-lg"
              @click="$router.push('/anjungan-mandiri')"
            >
              Kembali
            </b-button> -->
            <h2
              @click="$router.push('/anjungan-mandiri')"
              style="cursor: pointer"
            >
              <b-badge
                pill
                class="px-10 btn-cancel py-6"
                style="background: #dc10eb; color: white; font-size: 24px"
                >Kembali</b-badge
              >
            </h2>
          </div>
        </div>
        <!-- Breadcrumb::Start -->
        <!-- <h1 style="font-size: 35px; color: #bbc15d; font-weight: 600">
          <span class="h3"> Berita Desa</span>
        </h1> -->
        <h1 style="color: white; font-weight: 600">DAFTAR BERITA DESA</h1>
        <!-- Breadcrumb::End -->
        <div class="mt-auto" style="position: relative; top: 17px">
          <div class="row">
            <div class="col-md-4" v-for="(item, index) in news" :key="index">
              <div
                class="card my-2"
                @click="setModal(item.id)"
                style="border-radius: 30px !important; min-height: 520px"
              >
                <div class="card-body" style="min-height: 400px">
                  <div class="row" v-if="item.yt_link == ''">
                    <div
                      class="col-md-12 d-flex justify-content-center"
                      style="width: 400px; height: 300px"
                    >
                      <img
                        style="width: 100%; height: 100%; object-fit: contain"
                        :src="item.image"
                        alt="Gambar Desa"
                      />
                    </div>
                    <div class="col-md-12">
                      <div class="row p-0">
                        <div class="col-md-12">
                          <h2
                            class="font-weight-bold"
                            style="font-size: 25px !important"
                          >
                            {{ item.title }}
                          </h2>
                        </div>
                        <div class="col-md-12">
                          <p style="font-size: 15px !important">
                            {{ item.excerpt.slice(0, 300) + "..." }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="item.yt_link != ''">
                    <div class="col-md-12 d-flex justify-content-center">
                      <iframe
                        width="500"
                        height="300"
                        :src="item.yt_link"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div class="col-md-12">
                      <div class="row p-0">
                        <div class="col-md-12">
                          <h2
                            class="font-weight-bold"
                            style="font-size: 25px !important"
                          >
                            {{ item.title }}
                          </h2>
                        </div>
                        <div class="col-md-12">
                          <p style="font-size: 15px !important">
                            {{ item.excerpt.slice(0, 300) + "..." }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Menu::End -->
      <!-- Footer::Start -->
      <div class="curved-footer sample2">
        <div class="d-flex justify-content-center">
          <h1 style="font-size: 100px; font-weight: 600; color: #1dac74">
            {{ time }}
          </h1>
        </div>
        <div class="d-flex justify-content-center">
          <span style="color: #3b4c42; font-size: 30px" class="text-uppercase"
            >selamat datang di website desa {{ config.village_name }}</span
          >
        </div>
      </div>
      <!-- Footer::End -->
      <b-modal id="modal-xl" size="xl" scrollable centered hide-footer>
        <template #modal-header="{ close }">
          <HeaderModalFormLetter :title="'Berita'" :close="close" />
        </template>
        <template #modal-title
          ><span style="font-size: 25px" class="font-weight-bold"
            >Berita</span
          ></template
        >
        <DetailBeritaDesa :id="dataId"></DetailBeritaDesa>
      </b-modal>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DetailBeritaDesa from "@/view/pages/anjungan-mandiri/DetailBeritaDesa.vue";
import HeaderModalFormLetter from "@/view/components/anjungan-mandiri/HeaderModalFormLetter.vue";

export default {
  data() {
    return {
      news: {},
      config: {},
      interval: null,
      time: null,

      modal2: false,
      hamlets: [],
      dataId: "",
    };
  },
  components: {
    DetailBeritaDesa,
    HeaderModalFormLetter,
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
    setModal(id) {
      this.dataId = id;
      this.$bvModal.show("modal-xl");
    },
    async getNews() {
      let response = await module.get("news");
      if (response != null) {
        this.news = response;
        console.log(this.news, "this.news");
      }
    },
  },
  mounted() {
    this.getSetting();
    this.getNews();
    // this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 230px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.form-control-lg {
  height: 70px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}
</style>
